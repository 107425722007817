import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import { HeroH1, HeroP } from '../styles/Utility.styles'

export const HeroRegion = styled.section`
  grid-column: 2 / 3;
  margin-bottom: 40px;
`

export const HeroTitle = styled(HeroH1)`
  padding: 40px 0 14px 0;

  @media ${DEVICE.tabletL} {
    padding: 50px 0 20px 0;
  }
`

export const HeroBody = styled(HeroP)`
  /* Naming convenience */
`
