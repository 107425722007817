import React from 'react'
import { object, array } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

import * as Styled from './styles/EventsList.styles'
import { RegionContainer } from './styles/Utility.styles'

import { EventRow } from '../components/ContentBlocks/EventListingBlock'

const EventsList = ({ doc, events }) => (
  <>
    <RegionContainer>
      <Styled.HeroRegion>
        <Styled.HeroTitle>{doc.title?.text ?? ''}</Styled.HeroTitle>
        <Styled.HeroBody as="div">
          {doc.body_text && <PrismicRichText field={doc.body_text.richText} />}
        </Styled.HeroBody>
      </Styled.HeroRegion>
    </RegionContainer>

    <RegionContainer>
      {events.map((event, index) => {
        return (
          <EventRow
            key={event.node?.id ? event.node.id : event.id}
            event={event.node?.data ? event.node.data : event}
            isLast={index >= events.length - 1}
          />
        )
      })}
    </RegionContainer>
  </>
)

EventsList.propTypes = {
  doc: object,
  events: array,
}

export default EventsList
